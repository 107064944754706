import {
  Dispatch,
  ReactElement,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CognitoUser } from '@aws-amplify/auth';
import { Auth, Hub } from 'aws-amplify';
import { useApollo } from '@/lib/apolloClient';
import { createHttpLink } from '@apollo/client';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

interface UserContextType {
  user: {
    cognitoUser: CognitoUser | null;
    userId: number;
    userLanguage: string;
  } | null;
  setUser: Dispatch<
    SetStateAction<{
      cognitoUser: CognitoUser | null;
      userId: number;
      userLanguage: string;
    } | null>
  >;
  googleLogin: any;
  facebookLogin: any;
  refreshAccessToken: any;
}

const userContext = createContext<UserContextType>({} as UserContextType);

interface Props {
  children: React.ReactNode;
}

export default function AuthContext({ children }: Props): ReactElement {
  const [user, setUser] = useState<{
    cognitoUser: CognitoUser | null;
    userId: number;
    userLanguage: string;
  } | null>(null);
  const apolloClient = useApollo({});

  const googleLogin = async () => {
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };
  const facebookLogin = async () => {
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
    });
  };

  async function refreshAccessToken(reload: any) {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

      const refreshToken = currentSession.getRefreshToken();
      if (refreshToken && refreshToken.getToken()) {
        currentUser.refreshSession(refreshToken, (err: any, session: any) => {
          if (err) {
            console.error('Error refreshing token', err);
          } else {
            const newAccessToken = session.getIdToken().getJwtToken();
            apolloClient.setLink(
              createHttpLink({
                fetch,
                uri: process.env.NEXT_PUBLIC_API_URI,
                headers: {
                  authorization: newAccessToken
                    ? `Bearer ${newAccessToken}`
                    : '',
                },
              }),
            );
          }
          reload();
        });
      }
    } catch (error) {
      console.error('Error during token refresh', error);
    }
  }

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    Hub?.listen('auth', () => {
      checkUser();
    });
  }, []);

  async function checkUser() {
    try {
      const session = await Auth.currentSession();
      const refreshToken = await session.getRefreshToken();
      const amplifyUser = await Auth.currentAuthenticatedUser();

      const accessTokenExpiration = session.getIdToken().getExpiration();

      const hasuraPayload =
        amplifyUser?.signInUserSession?.idToken?.payload?.[
          'https://hasura.io/jwt/claims'
        ];

      const userId = hasuraPayload
        ? JSON.parse(hasuraPayload)['x-hasura-user-id']
        : null;

      const userLanguage = hasuraPayload
        ? JSON.parse(hasuraPayload)['x-hasura-language']
        : null;

      if (
        accessTokenExpiration &&
        new Date(accessTokenExpiration * 1000) < new Date()
      ) {
        // Access token đã hết hạn, thực hiện refresh token
        if (refreshToken?.getToken()) {
          await amplifyUser?.refreshSession(
            refreshToken,
            async (refErr: any, refSession: any) => {
              if (refErr) {
                throw refErr;
              } else {
                // Cập nhật access token mới vào Apollo Client
                const newJwtToken = refSession.getIdToken().getJwtToken();
                apolloClient.setLink(
                  createHttpLink({
                    fetch,
                    uri: process.env.NEXT_PUBLIC_API_URI,
                    headers: {
                      authorization: newJwtToken ? `Bearer ${newJwtToken}` : '',
                    },
                  }),
                );
              }
            },
          );
        }
      }
      const newJwtToken = session.getIdToken().getJwtToken();
      apolloClient.setLink(
        createHttpLink({
          fetch,
          uri: process.env.NEXT_PUBLIC_API_URI,
          headers: {
            authorization: newJwtToken ? `Bearer ${newJwtToken}` : '',
          },
        }),
      );

      if (amplifyUser) {
        setUser({ cognitoUser: amplifyUser, userId, userLanguage });
      }
    } catch (error) {
      //No current user
      setUser(null);
      apolloClient.setLink(
        createHttpLink({
          fetch,
          uri: process.env.NEXT_PUBLIC_API_URI,
          headers: {
            // 'x-hasura-language': 'vi_VN',
          },
        }),
      );
    }
  }

  return (
    <userContext.Provider
      value={{ user, setUser, googleLogin, facebookLogin, refreshAccessToken }}
    >
      {children}
    </userContext.Provider>
  );
}

export const useUser = (): UserContextType => useContext(userContext);
