import React from 'react';
import { Footer, Header } from '.';
import { LayoutProps } from '@/interfaces/common';

const DefaultLayout = ({ children }: LayoutProps) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default DefaultLayout;
