import DefaultLayout from '@/components/Layouts/DefaultLayout';
import { AppPropsWithLayout } from '@/interfaces/common';
import { useApollo } from '@/lib/apolloClient';
import '@/styles/globals.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ApolloProvider } from '@apollo/client';
import { Open_Sans } from 'next/font/google';
import { Fragment } from 'react';
import { Amplify } from 'aws-amplify';
import AuthContext from '@/contexts/AuthContext';
import { Toaster } from 'react-hot-toast';
import { ConfigProvider } from 'antd';

export const inter = Open_Sans({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-inter',
});

Amplify.configure({
  region: process.env.NEXT_PUBLIC_AWS_REGION,
  userPoolId: process.env.NEXT_PUBLIC_AWS_COGNITO_POOL_ID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_COGNITO_APP_CLIENT_ID,
  oauth: {
    domain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
    redirectSignIn: process.env.NEXT_PUBLIC_REDIRECT_WEBSITE_URL,
    redirectSignOut: process.env.NEXT_PUBLIC_REDIRECT_WEBSITE_URL,
    responseType: 'token',
  },
});

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const apolloClient = useApollo(pageProps);

  const Layout =
    Component.Layout === null ? Fragment : Component.Layout ?? DefaultLayout;
  return (
    <div
      style={{
        animation: 'animate-fadein 0.5s ease-in-out',
      }}
      className={`${inter.variable} font-sans text-black`}
    >
      <ApolloProvider client={apolloClient}>
        <AuthContext>
          <Layout>
            <ConfigProvider
              theme={{
                token: {},
              }}
            >
              <Component {...pageProps} />
            </ConfigProvider>
            <div>
              <Toaster />
            </div>
          </Layout>
        </AuthContext>
      </ApolloProvider>
    </div>
  );
}
