import dynamic from 'next/dynamic';

export const Header = dynamic(() => import('./Header'));
export const Footer = dynamic(() => import('./Footer'));
export const WrapperTwoLayout = dynamic(() => import('./WrapperTwoLayout'));
export const AuthenticationLayout = dynamic(
  () => import('./AuthenticationLayout'),
);
export const DefaultLayout = dynamic(() => import('./DefaultLayout'));
export const UserSideBar = dynamic(() => import('./UserSideBar'));
export const UserLayout = dynamic(() => import('./UserLayout'));
